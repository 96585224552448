$lg_s: 10;
$md_s: 8;
$xs_s: 7;



@import "sub-styles/fonts.scss";
@import "sub-styles/layout.scss";
@import "sub-styles/mfp.scss";
@import "sub-styles/animations.scss";


@import "sub-styles/header.scss";

.is-invalid {
    border: 1px solid red;
}

body {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.main{
  background-image: url(../img/bg0@2x.png);
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  &:before{
    content:' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  }

  @media (min-width: 1920px) {
    min-height: 850px;
  }
}
.main__header{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-bottom: 10rem;
}
.main__form-bg{
  margin-bottom: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url(../img/bg_form.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 8.3rem;
  z-index: 2;
}
.main__form__input{
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0px;
  width: 20rem;
  height: 4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-size: 1.4rem;
  color: #707070;
  &::placeholder{
    color: #707070;
  }
}
.main__form__submit{
  margin-left: 1rem;
  margin-right: 1rem;
  width: 28.5rem;
  height: 4rem;
  background: #FFFF00;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #203A3D;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.2s ease;
  &:hover{
    background: red;
    color: #fff;
  }
}

.welcome{
  height: 80rem;
  height: 480px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 10rem;
  position: relative;
  overflow-x: hidden;
  @media(max-width: 992px) {
    flex-wrap: wrap;
    padding-bottom: 15px !important;
  }
  @media(max-width: 700px) {
    padding-bottom: 15px !important;
  }
}
.welcome__bg{
  position: absolute;
  right: 0;
  top: 0;
  width: 53.05%;
  width: 722px;
  height: 100%;
  height: 540;
  background-image: url(../img/bg_welcome@x2.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% auto;

  @media(max-width: 1450px) {
    background-position: 0px 100%;
    background-size: auto 100%;
  }

  @media (max-width: 1150px) {
    margin-right: -100px;
  }
  @media (max-width: 1050px) {
    margin-right: -200px;
  }
  @media(max-width: 992px) {
    margin-right: 0;
    position: static;
    order: 2;
    width: 100%;
    background-position: center center;
    margin-top: 20px;
  }
  @media(max-width: 700px) {
    width: 36rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.welcome__info{
  width: 46rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
}
.section__sub-header{
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.5rem;
}
.section__sub-header-bold{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 2.5rem;
}
.section__text{
  font-weight: 300;
  line-height: 2.5rem;
  margin-top: 1.4rem;
  font-size: 1.6rem;
}
.text-normal{
  font-weight: 400;
  width: 38.5rem;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2.4rem;
}

.resort{
  background: rgba(242, 239, 238, 0.5);
  height: 64rem;
  padding-top: 5rem;
  position: relative;
  &:after{
    content:' ';
    display: block;
    background: url(../img/waves.png) repeat-x 0px 100% / 100% auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10rem;
  }
  & .section__text{
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
  overflow-x: hidden;

  @media(max-width: 992px) {
    padding-bottom: 0 !important;
  }
}
.resort__bg{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 803px;
  max-width: 100rem;
  height: 601px;
  background-image: url(../img/bg_resort@2x.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 803px 601px;

  @media(max-width: 1440px) {
    margin-left: -100px;
  }
  @media(max-width: 1250px) {
    margin-left: -200px;
  }
  @media(max-width: 1150px) {
    margin-left: -300px;
  }
  @media(max-width: 992px) {
    position: static;
    margin-left: 0;
    height: 451px
  }
  @media(max-width: 900px) {
    height: 351px
  }
  @media(max-width: 850px) {
    height: 351px
  }
  @media(max-width: 700px) {
    width: 100% !important;
    height: 401px !important;
  }
  @media(max-width: 600px) {
    height: 351px !important;
  }
  @media(max-width: 550px) {
    height: 321px !important;
  }
  @media(max-width: 450px) {
    height: 301px !important;
  }
  @media(max-width: 400px) {
    height: 231px !important;
  }
}
.section__header{
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
.resort__container{
  margin-top: 15rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.resort__info{
  width: 46rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}
.resort__sub-header{
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 700;
}
.section__order{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFF00;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0px;
  width: 16.5rem;
  height: 3.8rem;
  font-size: 1.2rem;
  color: #725303;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 1.5rem;
  transition: all 0.2s ease;
  &:hover{
    background: red;
    color: #fff;
  }
}

.dahang{
  height: 57.5rem;
  padding-top: 7.5rem;
  position: relative;
  & .section__order{
    margin-top: 2.5rem;
  }
}
.dahang__bg{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60.69%;
  max-width: 100rem;
  height: 100%;
  background-image: url(../img/bg_danang@2x.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 100%;
}
.dahang__info{
  width: 100%;
  max-width: 460px;
  position: relative;
  z-index: 10;
}



.fukuok{
  height: 62.6rem;
  padding-top: 11.8rem;
  background: url(../img/bg_fukuok@2x.png);
  background-size: 896px 526px;
  background-position: top left;
  background-repeat: no-repeat;

  @media (max-width: 992px) {
    background-position: bottom left;
  }

  @media (max-width: 600px) {
    padding-bottom: 25rem !important;
  }
  @media (max-width: 400px) {
    padding-bottom: 20rem !important;
  }
}
.fukuok__container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.fukuok__info{
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}
.fukuok__text {
    margin-top: 0rem;
}

.pingu{
  height: auto;
  padding-top: 100px;
  padding-bottom: 400px;
  background: url(../img/bg_pingu@2x.jpg);
  background-size: 52.7% auto;
  background-position: top 100%;
  background-repeat: no-repeat;

  @media (min-width: 1921px) {
    background-size: 1000px auto;
  }

  @media (max-width: 1700px) {
    background-size: 800px auto;
    padding-bottom: 400px;
  }
  @media (max-width: 1400px) {
    background-size: 600px auto;
    padding-bottom: 200px;
  }
  @media (max-width: 1150px) {
    background-size: 500px auto;
    padding-bottom: 100px;
  }

  @media (max-width: 992px) {
    background-position: bottom left;
    background-size: 700px auto;
    padding-bottom: 600px;
  }
  @media (max-width: 650px) {
    background-size: 500px auto;
    padding-bottom: 450px;
  }
  @media (max-width: 450px) {
    background-size: 350px auto;
    padding-bottom: 300px;
  }
}
.pingu__container{
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.pingu__info{
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}
.pingu__text {
    margin-top: 0rem;
}

.uroa{
  margin-top: 100px;
  height: auto;
  padding-top: 100px;
  padding-bottom: 300px;
  background: url(../img/bg_uroa@2x.jpg);
  background-size: 52.7% auto;
  background-position: top left;
  background-repeat: no-repeat;
  
  @media (min-width: 1441px) {
    background-size: 800px auto;
  } 
  @media (max-width: 1440px) {
    padding-bottom: 200px;
  } 
  @media (max-width: 1150px) {
    background-size: 500px auto;
    padding-bottom: 100px;
  } 
  @media (max-width: 1050px) {
    background-size: 400px auto;
    padding-bottom: 100px;
  } 
  @media (max-width: 992px) {
    margin-top:0;
    padding-bottom: 600px;
    background-position: bottom left;
    background-size: 700px auto;
  }
  @media (max-width: 700px) {
    margin-bottom: 50px;
  }
  @media (max-width: 750px) {
    padding-bottom: 400px;
    background-size: 500px auto;
  }
  @media (max-width: 550px) {
    padding-bottom: 300px;
    background-size: 350px auto;
  }
  @media (max-width: 400px) {
    padding-bottom: 250px;
    background-size: 250px auto;
  }
}

.uroa__container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.uroa__info{
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}
.uroa__text {
    margin-top: 0rem;
}

.fanet{
  height: 70rem;
  padding-top: 8.9rem;
  background: url(../img/bg_fanet@2x.png);
  background-size: 48.62% auto;
  background-position: top right;
  background-repeat: no-repeat;
  & .section__order{
    margin-top: 3.4rem;
  }

  @media(max-width: 992px) {
    background-position: bottom right;
  }
  @media(max-width: 550px) {
    padding-bottom: 45rem !important;
  }
  @media(max-width: 400px) {
    padding-bottom: 37rem !important;
  }
}
.fanet__container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.fanet__info{
  width: 51.6rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}

.kizimkazi{
  height: auto;
  padding-top: 100px;
  padding-bottom: 250px;
  background: url(../img/bg_kizimkazi@2x.jpg);
  background-size: 48.62% auto;
  background-position: top right;
  background-repeat: no-repeat;
  & .section__order{
    margin-top: 3.4rem;
  }

  @media (max-width: 1440px) {
    padding-bottom: 50px;
  }

  @media (max-width: 992px) {
    background-size: 700px auto;
    padding-bottom: 450px;
    background-position: bottom right;
  }

  @media (max-width: 700px) {
    background-size: 500px auto;
    padding-bottom: 350px;
  }

  @media (max-width: 500px) {
    background-size: 350px auto;
    padding-bottom: 250px;
  }
}
.kizimkazi__container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.kizimkazi__info{
  width: 51.6rem;
  max-width: 100%;
  position: relative;
  z-index: 10;
}

.hotels{
  padding-top: 7.7rem;
  padding-bottom: 10.8rem;
}
.hotels__grid{
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.hotels__item{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  width: 33%;
  max-width: 36rem;
  height: 32.5rem;
  position: relative;
  cursor: pointer;
  &:after{
    content:' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16.5rem;
    background: linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, rgba(0, 0, 0, 0.7) 83.86%);
    z-index: 2;
  }
}
.hotels__figure{
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.hotels__img{
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.hotels__item__info{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: #fff;
}

.hotels__stars{
  padding-left: 1.5rem;
  & svg{
    width: 1.35rem;
    height: 1.7rem;
    vertical-align: bottom;
  }
}
.hotels__stars__text{
  display: inline-block;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 1;
  vertical-align: bottom;
}
.hotels__item__name{
  padding-left: 1.5rem;
  margin-top: 0.6rem;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.hotels__stars{
  display: inline-block;
}
.hotels__item__footer{
  padding-left: 1.5rem;
  margin-bottom: 0.6rem;
  background: #1C4F6B;
  padding-right: 1.5rem;
  height: 3.8rem;
  width: 27.4rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  @media screen and (max-width: 992px) {
    width: 22.4rem;
  }
}
.hotels__item__bb{
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
}
.hotels__item__price{
  color: #89DEF9;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
}

.know{
  padding-top: 100px;
  margin-bottom: 50px;
  height: auto;
  background: url(../img/bg_know@2x.png);
  background-size: 700px auto;
  background-position: bottom right;
  background-repeat: no-repeat;

  @media (max-width: 1440px) {
    background-size: 600px auto;
  }

  @media (max-width: 1200px) {
    background-size: 550px auto;
  }

  @media (max-width: 992px) {
    padding-bottom: 550px !important;
    background-size: 700px auto !important;
  }
  @media (max-width: 700px) {
    padding-bottom: 400px !important;
    background-size: 500px auto !important;
  }
  @media (max-width: 450px) {
    padding-bottom: 300px !important;
    background-size: 350px auto !important;
  }
}
.know__info{
  margin-top: 7.7rem;
}
.know__info__item{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.7rem;
  & p{
    margin-bottom: 0;
  }
}
.know__info__item-center{
  align-items: center;
}
.know__info__item__figure{
  width: 6.4rem;
  text-align: center;
}
.know__info__item__text{
  margin-left: 3rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2rem;
  max-width: 55.6rem;
  flex: 1;
}
.know__info__item__text2{
  max-width: 28.7rem;
}
.know__info__item__text3{
  max-width: 36rem;
}
.know__info__item__text4{
  max-width: 33.5rem;
}
.know__info__item__text5{
  max-width: 36.0rem;
}

.why{
  height: auto;
  padding-top: 100px;
  padding-bottom: 80px;
  background-image: url(../img/bg_why@2x.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 1081px 434px;

  @media (max-width: 992px) {
    height: auto !important;
  }
  @media (max-width: 750px) {
    padding-bottom: 40px;
  }
  @media (max-width: 700px) {
    background-size: 100% auto !important;
    padding-bottom: 400px !important;
  }
  @media (max-width: 500px) {
    padding-bottom: 300px !important;
  }
  @media (max-width: 400px) {
    padding-bottom: 250px !important;
  }
  margin-bottom: 50px;
}
.why__header{
  font-weight: 500;
  font-size: 3.5rem;
  line-height: 4.5rem;
}
.why__container{
  margin-top: 6.7rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.why__item{
  margin-left: 3.7rem;
  margin-right: 3.7rem;
  text-align: center;
}
.why__img{
  height: 9rem;
}
.why__item__text{
  margin-top: 2rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
  color: #656565;
  text-align: center;
}
.why__blue__order{
  margin: 0 auto;
  margin-top: 380px;
  color: #fff;
  background: #FF0000;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.2s ease;
  &:hover{
      background: #ff0;
      color: #203a3d;
  }

  @media (max-width: 992px) {
    margin-top: 350px;
  }
  @media (max-width: 850px) {
    margin-top: 300px;
  }
  @media (max-width: 750px) {
    margin-top: 250px;
  }
}

.footer{
  background: url(../img/bg_footer.png) 50% 50% / cover;
}
.footer__container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 15rem;
  color: #fff;
}
.footer__text{
  margin-left: 5rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  & p{
    margin-bottom: 0;
  }
}
.footer__logo__img {
    width: 14.6rem;
}
.footer__soc{
  & a{
    margin-right: 1.1rem;
    & svg path.white{
      fill: #fff;
    }
    & svg path.black{
      fill: #000;
    }
    &:hover svg path{
      fill: inherit;
    }
  }
}
.footer-phone-number {
  padding: 30px;
  font-weight: 600;
}
.footer__order{
  width: 16.5rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  background: #FF0000;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 2.3rem;
  &:hover{
    background: #fff;
    color: red;
  }
}

.footer__actions{
  flex:1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & a svg path{
    transition:all 0.2s ease;
  }
  & a:hover svg path.white{
    fill: red;
  }
  & a:hover svg path.black{
    fill: #000;
  }
}

.header_shadow {
    position: fixed;
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 1px 1px 4px rgba(0,0,0,.2);
    & .header__logo__img {
        height: 4rem;
    }
}

@import "sub-styles/retina.scss";


@media (max-width:1700px){
  .dahang__bg{
    background-position: 0px 100%;
  }
}
@media (max-width:1450px){
  .welcome{
    // height: 60rem;
  }
  section{
    min-height: 0;
  }
  section.welcome{
    min-height: 0;
  }
  // .main{
  //   background-image: url(../img/bg0.png);
  // }

  // .resort__bg{
  //   background-image: url(../img/bg_resort.png);
  // }
  // .dahang__bg{
  //   background-image: url(../img/bg_danang.png);
  // }
  // .fukuok{
  //   background-image: url(../img/bg_fukuok.png);
  // }
  // .fanet{
  //   background-image: url(../img/bg_fanet.png);
  // }
  // .know__bg{
  //   background-image: url(../img/bg_know.png);
  // }
}

@media (max-width:1150px){
  .hotels__item{
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width:2050px){
  html{
    font-size: 16px;
  }
}

@import "sub-styles/mobile.scss";