@import url(../../../node_modules/magnific-popup/dist/magnific-popup.css);
@import url(../../../node_modules/animate.css/animate.min.css);
:root {
  --blue: #ffa974;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #cc2eaa;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #2ecc71;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ffa974;
  --secondary: #6c757d;
  --success: #2ecc71;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #cc2eaa;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1500px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ffa974;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #ff7a28;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1200px !important; }
  .container {
    min-width: 1200px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ffa974 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff8941 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #2ecc71 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #25a25a !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #cc2eaa !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a22587 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ffa974 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #2ecc71 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #cc2eaa !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1500px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1500px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1500px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1500px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1500px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #ffa974 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #ff7a28 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #2ecc71 !important; }

a.text-success:hover, a.text-success:focus {
  color: #208e4e !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #cc2eaa !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #8e2076 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Light.eot");
  src: url("../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.svg") format("svg"), url("../fonts/Roboto-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.svg") format("svg"), url("../fonts/Roboto-Regular.ttf") format("truetype"); }

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.svg") format("svg"), url("../fonts/Roboto-Medium.ttf") format("truetype"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.svg") format("svg"), url("../fonts/Roboto-Bold.ttf") format("truetype"); }

* {
  outline: none !important; }

*:-webkit-scrollbar-button {
  background-image: url("");
  background-repeat: no-repeat;
  width: 3px;
  height: 0px; }

*::-webkit-scrollbar-track {
  background: transparent; }

*::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 90px;
  background: red; }

*::-webkit-scrollbar-thumb:hover {
  background: red; }

*::-webkit-resizer {
  background-image: url("");
  background-repeat: no-repeat;
  width: 3px;
  height: 0px; }

*::-webkit-scrollbar {
  width: 3px; }

a {
  transition: all 0.2s ease;
  color: inherit; }
  a:hover {
    text-decoration: none;
    color: inherit; }

html {
  font-size: 10px;
  position: relative; }

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;
  font-size: 1.5rem;
  color: #0d0d1e;
  letter-spacing: 0em; }

.container {
  width: 100%;
  max-width: 117rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto; }

.white-popup {
  position: relative;
  padding: 0px;
  width: auto;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

/*

====== Zoom effect ======

*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8); }
  .mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0; }
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }

/*

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-move-horizontal .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;
    transform: translateX(-50px); }
  .mfp-move-horizontal.mfp-bg {
    opacity: 0;
    transition: all 0.3s; }
  .mfp-move-horizontal.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: translateX(0); }
  .mfp-move-horizontal.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-move-horizontal.mfp-removing .mfp-with-anim {
    transform: translateX(50px);
    opacity: 0; }
  .mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0; }

/*

====== Zoom-out effect ======

*/
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-out .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3); }
  .mfp-zoom-out.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-out.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-out.mfp-removing .mfp-with-anim {
    transform: scale(1.3);
    opacity: 0; }
  .mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0; }

/*

====== Move-from-top effect ======

*/
.mfp-move-from-top {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-move-from-top .mfp-content {
    vertical-align: top; }
  .mfp-move-from-top .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(-100px); }
  .mfp-move-from-top.mfp-bg {
    opacity: 0;
    transition: all 0.2s; }
  .mfp-move-from-top.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: translateY(0); }
  .mfp-move-from-top.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-move-from-top.mfp-removing .mfp-with-anim {
    transform: translateY(-50px);
    opacity: 0; }
  .mfp-move-from-top.mfp-removing.mfp-bg {
    opacity: 0; }

.order_modal {
  width: 94.6rem;
  max-width: 100%;
  height: 55rem;
  background: url(../img/bg_modal@2x.jpg) 0px 50%/contain no-repeat;
  border-radius: 0px; }

.order_modal__container {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  padding: 3rem;
  height: 100%; }

.order_modal__form {
  width: 38rem;
  height: 100%;
  background: rgba(28, 79, 107, 0.8);
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 5rem;
  position: relative; }

.product_modal__close {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 1.7rem;
  height: 1.7rem;
  background-color: transparent;
  border: 0px;
  padding-left: 0;
  padding-right: 0; }
  .product_modal__close svg {
    width: 100%;
    height: 100%; }

.action__form__header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 4rem;
  color: #fff;
  text-align: center; }

.action__form__inputs {
  margin-top: 2.5rem; }

.action__form__input {
  margin-top: 1.5rem;
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: #656565;
  width: 100%;
  height: 4.5rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem; }
  .action__form__input:placeholder {
    color: #656565; }

.action__form__btn {
  margin-top: 1.5rem;
  color: #203A3D;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.5rem;
  background: #FFFF00;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  height: 4.5rem;
  border: 0px;
  text-transform: uppercase; }

.action__form__text {
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #fff;
  font-weight: 400;
  text-align: center; }

.action__form__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.action__form {
  height: 100%; }

#ty_modal .order_modal__form {
  padding-top: 0; }

@-webkit-keyframes slideBottom {
  0% {
    -webkit-transform: translateY(-12%);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0%);
    opacity: 1; } }

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 0.9s;
  -webkit-animation-duration: 0.9s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 1; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  z-index: 20;
  transition: all .3s ease-in-out; }

.header__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative; }

.header__nav {
  margin-left: 1.3rem; }

.header__menu {
  margin-top: 5px;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }
  .header__menu li {
    display: inline-block;
    margin-left: 0.6rem;
    margin-right: 0.6rem; }
    .header__menu li a {
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 2.8rem; }
      .header__menu li a:hover {
        color: red; }

.header__soc {
  order: 4;
  display: inline-block;
  margin-left: 0.9rem; }
  .header__soc a {
    margin-right: 1rem; }
    .header__soc a svg path {
      transition: all 0.5s ease; }
    .header__soc a:hover svg path.red {
      fill: red; }
    .header__soc a:hover svg path.white {
      fill: white; }

.header__actions {
  order: 3;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }

.header__order {
  width: 137px;
  height: 3.3rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FF0000;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.2s ease; }
  .header__order:hover {
    background: #ff0;
    color: #203a3d; }

.header-phone-number {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #164194;
  top: 20px;
  right: 240px; }
  @media (max-width: 1200px) {
    .header-phone-number {
      top: 70px;
      right: 50px; } }

.header_shadow .header-phone-number {
  top: 9px;
  right: 240px; }
  @media (max-width: 1200px) {
    .header_shadow .header-phone-number {
      padding: 20px 50px 20px 50px;
      background-color: white;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      top: 55px;
      right: 0; } }

.is-invalid {
  border: 1px solid red; }

body {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto; }

.main {
  background-image: url(../img/bg0@2x.png);
  background-position: center bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  position: relative; }
  .main:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20rem;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    /* IE6-9 */ }
  @media (min-width: 1920px) {
    .main {
      min-height: 850px; } }

.main__header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding-bottom: 10rem; }

.main__form-bg {
  margin-bottom: 12rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url(../img/bg_form.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 8.3rem;
  z-index: 2; }

.main__form__input {
  background: #fff;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0px;
  width: 20rem;
  height: 4rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  font-size: 1.4rem;
  color: #707070; }
  .main__form__input::placeholder {
    color: #707070; }

.main__form__submit {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 28.5rem;
  height: 4rem;
  background: #FFFF00;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  color: #203A3D;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.2s ease; }
  .main__form__submit:hover {
    background: red;
    color: #fff; }

.welcome {
  height: 80rem;
  height: 480px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 10rem;
  position: relative;
  overflow-x: hidden; }
  @media (max-width: 992px) {
    .welcome {
      flex-wrap: wrap;
      padding-bottom: 15px !important; } }
  @media (max-width: 700px) {
    .welcome {
      padding-bottom: 15px !important; } }

.welcome__bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 53.05%;
  width: 722px;
  height: 100%;
  height: 540;
  background-image: url(../img/bg_welcome@x2.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% auto; }
  @media (max-width: 1450px) {
    .welcome__bg {
      background-position: 0px 100%;
      background-size: auto 100%; } }
  @media (max-width: 1150px) {
    .welcome__bg {
      margin-right: -100px; } }
  @media (max-width: 1050px) {
    .welcome__bg {
      margin-right: -200px; } }
  @media (max-width: 992px) {
    .welcome__bg {
      margin-right: 0;
      position: static;
      order: 2;
      width: 100%;
      background-position: center center;
      margin-top: 20px; } }
  @media (max-width: 700px) {
    .welcome__bg {
      width: 36rem !important;
      padding-left: 3rem !important;
      padding-right: 3rem !important;
      margin-left: auto;
      margin-right: auto; } }

.welcome__info {
  width: 46rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  z-index: 10; }

.section__sub-header {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.5rem; }

.section__sub-header-bold {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 2.5rem; }

.section__text {
  font-weight: 300;
  line-height: 2.5rem;
  margin-top: 1.4rem;
  font-size: 1.6rem; }

.text-normal {
  font-weight: 400;
  width: 38.5rem;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 2.4rem; }

.resort {
  background: rgba(242, 239, 238, 0.5);
  height: 64rem;
  padding-top: 5rem;
  position: relative;
  overflow-x: hidden; }
  .resort:after {
    content: ' ';
    display: block;
    background: url(../img/waves.png) repeat-x 0px 100%/100% auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10rem; }
  .resort .section__text {
    font-size: 1.5rem;
    line-height: 2.4rem; }
  @media (max-width: 992px) {
    .resort {
      padding-bottom: 0 !important; } }

.resort__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 803px;
  max-width: 100rem;
  height: 601px;
  background-image: url(../img/bg_resort@2x.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 803px 601px; }
  @media (max-width: 1440px) {
    .resort__bg {
      margin-left: -100px; } }
  @media (max-width: 1250px) {
    .resort__bg {
      margin-left: -200px; } }
  @media (max-width: 1150px) {
    .resort__bg {
      margin-left: -300px; } }
  @media (max-width: 992px) {
    .resort__bg {
      position: static;
      margin-left: 0;
      height: 451px; } }
  @media (max-width: 900px) {
    .resort__bg {
      height: 351px; } }
  @media (max-width: 850px) {
    .resort__bg {
      height: 351px; } }
  @media (max-width: 700px) {
    .resort__bg {
      width: 100% !important;
      height: 401px !important; } }
  @media (max-width: 600px) {
    .resort__bg {
      height: 351px !important; } }
  @media (max-width: 550px) {
    .resort__bg {
      height: 321px !important; } }
  @media (max-width: 450px) {
    .resort__bg {
      height: 301px !important; } }
  @media (max-width: 400px) {
    .resort__bg {
      height: 231px !important; } }

.section__header {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center; }

.resort__container {
  margin-top: 15rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end; }

.resort__info {
  width: 46rem;
  max-width: 100%;
  position: relative;
  z-index: 10; }

.resort__sub-header {
  font-size: 2.4rem;
  line-height: 2rem;
  font-weight: 700; }

.section__order {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFF00;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0px;
  width: 16.5rem;
  height: 3.8rem;
  font-size: 1.2rem;
  color: #725303;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 1.5rem;
  transition: all 0.2s ease; }
  .section__order:hover {
    background: red;
    color: #fff; }

.dahang {
  height: 57.5rem;
  padding-top: 7.5rem;
  position: relative; }
  .dahang .section__order {
    margin-top: 2.5rem; }

.dahang__bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60.69%;
  max-width: 100rem;
  height: 100%;
  background-image: url(../img/bg_danang@2x.png);
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto 100%; }

.dahang__info {
  width: 100%;
  max-width: 460px;
  position: relative;
  z-index: 10; }

.fukuok {
  height: 62.6rem;
  padding-top: 11.8rem;
  background: url(../img/bg_fukuok@2x.png);
  background-size: 896px 526px;
  background-position: top left;
  background-repeat: no-repeat; }
  @media (max-width: 992px) {
    .fukuok {
      background-position: bottom left; } }
  @media (max-width: 600px) {
    .fukuok {
      padding-bottom: 25rem !important; } }
  @media (max-width: 400px) {
    .fukuok {
      padding-bottom: 20rem !important; } }

.fukuok__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end; }

.fukuok__info {
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10; }

.fukuok__text {
  margin-top: 0rem; }

.pingu {
  height: auto;
  padding-top: 100px;
  padding-bottom: 400px;
  background: url(../img/bg_pingu@2x.jpg);
  background-size: 52.7% auto;
  background-position: top 100%;
  background-repeat: no-repeat; }
  @media (min-width: 1921px) {
    .pingu {
      background-size: 1000px auto; } }
  @media (max-width: 1700px) {
    .pingu {
      background-size: 800px auto;
      padding-bottom: 400px; } }
  @media (max-width: 1400px) {
    .pingu {
      background-size: 600px auto;
      padding-bottom: 200px; } }
  @media (max-width: 1150px) {
    .pingu {
      background-size: 500px auto;
      padding-bottom: 100px; } }
  @media (max-width: 992px) {
    .pingu {
      background-position: bottom left;
      background-size: 700px auto;
      padding-bottom: 600px; } }
  @media (max-width: 650px) {
    .pingu {
      background-size: 500px auto;
      padding-bottom: 450px; } }
  @media (max-width: 450px) {
    .pingu {
      background-size: 350px auto;
      padding-bottom: 300px; } }

.pingu__container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end; }

.pingu__info {
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10; }

.pingu__text {
  margin-top: 0rem; }

.uroa {
  margin-top: 100px;
  height: auto;
  padding-top: 100px;
  padding-bottom: 300px;
  background: url(../img/bg_uroa@2x.jpg);
  background-size: 52.7% auto;
  background-position: top left;
  background-repeat: no-repeat; }
  @media (min-width: 1441px) {
    .uroa {
      background-size: 800px auto; } }
  @media (max-width: 1440px) {
    .uroa {
      padding-bottom: 200px; } }
  @media (max-width: 1150px) {
    .uroa {
      background-size: 500px auto;
      padding-bottom: 100px; } }
  @media (max-width: 1050px) {
    .uroa {
      background-size: 400px auto;
      padding-bottom: 100px; } }
  @media (max-width: 992px) {
    .uroa {
      margin-top: 0;
      padding-bottom: 600px;
      background-position: bottom left;
      background-size: 700px auto; } }
  @media (max-width: 700px) {
    .uroa {
      margin-bottom: 50px; } }
  @media (max-width: 750px) {
    .uroa {
      padding-bottom: 400px;
      background-size: 500px auto; } }
  @media (max-width: 550px) {
    .uroa {
      padding-bottom: 300px;
      background-size: 350px auto; } }
  @media (max-width: 400px) {
    .uroa {
      padding-bottom: 250px;
      background-size: 250px auto; } }

.uroa__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end; }

.uroa__info {
  width: 50.8rem;
  max-width: 100%;
  position: relative;
  z-index: 10; }

.uroa__text {
  margin-top: 0rem; }

.fanet {
  height: 70rem;
  padding-top: 8.9rem;
  background: url(../img/bg_fanet@2x.png);
  background-size: 48.62% auto;
  background-position: top right;
  background-repeat: no-repeat; }
  .fanet .section__order {
    margin-top: 3.4rem; }
  @media (max-width: 992px) {
    .fanet {
      background-position: bottom right; } }
  @media (max-width: 550px) {
    .fanet {
      padding-bottom: 45rem !important; } }
  @media (max-width: 400px) {
    .fanet {
      padding-bottom: 37rem !important; } }

.fanet__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end; }

.fanet__info {
  width: 51.6rem;
  max-width: 100%;
  position: relative;
  z-index: 10; }

.kizimkazi {
  height: auto;
  padding-top: 100px;
  padding-bottom: 250px;
  background: url(../img/bg_kizimkazi@2x.jpg);
  background-size: 48.62% auto;
  background-position: top right;
  background-repeat: no-repeat; }
  .kizimkazi .section__order {
    margin-top: 3.4rem; }
  @media (max-width: 1440px) {
    .kizimkazi {
      padding-bottom: 50px; } }
  @media (max-width: 992px) {
    .kizimkazi {
      background-size: 700px auto;
      padding-bottom: 450px;
      background-position: bottom right; } }
  @media (max-width: 700px) {
    .kizimkazi {
      background-size: 500px auto;
      padding-bottom: 350px; } }
  @media (max-width: 500px) {
    .kizimkazi {
      background-size: 350px auto;
      padding-bottom: 250px; } }

.kizimkazi__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end; }

.kizimkazi__info {
  width: 51.6rem;
  max-width: 100%;
  position: relative;
  z-index: 10; }

.hotels {
  padding-top: 7.7rem;
  padding-bottom: 10.8rem; }

.hotels__grid {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.hotels__item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  width: 33%;
  max-width: 36rem;
  height: 32.5rem;
  position: relative;
  cursor: pointer; }
  .hotels__item:after {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 16.5rem;
    background: linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, rgba(0, 0, 0, 0.7) 83.86%);
    z-index: 2; }

.hotels__figure {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

.hotels__img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.hotels__item__info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: #fff; }

.hotels__stars {
  padding-left: 1.5rem; }
  .hotels__stars svg {
    width: 1.35rem;
    height: 1.7rem;
    vertical-align: bottom; }

.hotels__stars__text {
  display: inline-block;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 1;
  vertical-align: bottom; }

.hotels__item__name {
  padding-left: 1.5rem;
  margin-top: 0.6rem;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase; }

.hotels__stars {
  display: inline-block; }

.hotels__item__footer {
  padding-left: 1.5rem;
  margin-bottom: 0.6rem;
  background: #1C4F6B;
  padding-right: 1.5rem;
  height: 3.8rem;
  width: 27.4rem;
  display: inline-flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem; }
  @media screen and (max-width: 992px) {
    .hotels__item__footer {
      width: 22.4rem; } }

.hotels__item__bb {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1; }

.hotels__item__price {
  color: #89DEF9;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1; }

.know {
  padding-top: 100px;
  margin-bottom: 50px;
  height: auto;
  background: url(../img/bg_know@2x.png);
  background-size: 700px auto;
  background-position: bottom right;
  background-repeat: no-repeat; }
  @media (max-width: 1440px) {
    .know {
      background-size: 600px auto; } }
  @media (max-width: 1200px) {
    .know {
      background-size: 550px auto; } }
  @media (max-width: 992px) {
    .know {
      padding-bottom: 550px !important;
      background-size: 700px auto !important; } }
  @media (max-width: 700px) {
    .know {
      padding-bottom: 400px !important;
      background-size: 500px auto !important; } }
  @media (max-width: 450px) {
    .know {
      padding-bottom: 300px !important;
      background-size: 350px auto !important; } }

.know__info {
  margin-top: 7.7rem; }

.know__info__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.7rem; }
  .know__info__item p {
    margin-bottom: 0; }

.know__info__item-center {
  align-items: center; }

.know__info__item__figure {
  width: 6.4rem;
  text-align: center; }

.know__info__item__text {
  margin-left: 3rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2rem;
  max-width: 55.6rem;
  flex: 1; }

.know__info__item__text2 {
  max-width: 28.7rem; }

.know__info__item__text3 {
  max-width: 36rem; }

.know__info__item__text4 {
  max-width: 33.5rem; }

.know__info__item__text5 {
  max-width: 36.0rem; }

.why {
  height: auto;
  padding-top: 100px;
  padding-bottom: 80px;
  background-image: url(../img/bg_why@2x.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 1081px 434px;
  margin-bottom: 50px; }
  @media (max-width: 992px) {
    .why {
      height: auto !important; } }
  @media (max-width: 750px) {
    .why {
      padding-bottom: 40px; } }
  @media (max-width: 700px) {
    .why {
      background-size: 100% auto !important;
      padding-bottom: 400px !important; } }
  @media (max-width: 500px) {
    .why {
      padding-bottom: 300px !important; } }
  @media (max-width: 400px) {
    .why {
      padding-bottom: 250px !important; } }

.why__header {
  font-weight: 500;
  font-size: 3.5rem;
  line-height: 4.5rem; }

.why__container {
  margin-top: 6.7rem;
  display: flex;
  align-items: flex-start;
  justify-content: center; }

.why__item {
  margin-left: 3.7rem;
  margin-right: 3.7rem;
  text-align: center; }

.why__img {
  height: 9rem; }

.why__item__text {
  margin-top: 2rem;
  font-size: 1.7rem;
  line-height: 2.3rem;
  color: #656565;
  text-align: center; }

.why__blue__order {
  margin: 0 auto;
  margin-top: 380px;
  color: #fff;
  background: #FF0000;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.2s ease; }
  .why__blue__order:hover {
    background: #ff0;
    color: #203a3d; }
  @media (max-width: 992px) {
    .why__blue__order {
      margin-top: 350px; } }
  @media (max-width: 850px) {
    .why__blue__order {
      margin-top: 300px; } }
  @media (max-width: 750px) {
    .why__blue__order {
      margin-top: 250px; } }

.footer {
  background: url(../img/bg_footer.png) 50% 50%/cover; }

.footer__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 15rem;
  color: #fff; }

.footer__text {
  margin-left: 5rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-transform: uppercase; }
  .footer__text p {
    margin-bottom: 0; }

.footer__logo__img {
  width: 14.6rem; }

.footer__soc a {
  margin-right: 1.1rem; }
  .footer__soc a svg path.white {
    fill: #fff; }
  .footer__soc a svg path.black {
    fill: #000; }
  .footer__soc a:hover svg path {
    fill: inherit; }

.footer-phone-number {
  padding: 30px;
  font-weight: 600; }

.footer__order {
  width: 16.5rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  background: #FF0000;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-transform: uppercase;
  margin-left: 2.3rem; }
  .footer__order:hover {
    background: #fff;
    color: red; }

.footer__actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .footer__actions a svg path {
    transition: all 0.2s ease; }
  .footer__actions a:hover svg path.white {
    fill: red; }
  .footer__actions a:hover svg path.black {
    fill: #000; }

.header_shadow {
  position: fixed;
  background: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); }
  .header_shadow .header__logo__img {
    height: 4rem; }

@media (max-width: 1700px) {
  .dahang__bg {
    background-position: 0px 100%; } }

@media (max-width: 1450px) {
  section {
    min-height: 0; }
  section.welcome {
    min-height: 0; } }

@media (max-width: 1150px) {
  .hotels__item {
    margin-left: 0;
    margin-right: 0; } }

@media (min-width: 2050px) {
  html {
    font-size: 16px; } }

.only_mobile {
  display: none; }

.only_pc {
  display: block; }

@media (max-width: 990px) {
  html {
    font-size: 10px; }
  body {
    font-size: 1.2rem; }
  .main {
    height: 85rem;
    min-height: 0; }
    .main:before {
      display: none; }
  .main__form-bg {
    margin-bottom: 18rem;
    max-width: 74rem;
    background-size: 108% auto; }
  .main__form__input {
    width: 14rem;
    height: 2.7rem;
    font-size: 1.2rem; }
  .main__form__submit {
    width: 15.5rem;
    height: 2.7rem;
    font-size: 1rem; }
  .welcome {
    padding-top: 2rem;
    height: auto;
    padding-bottom: 32rem; }
    .welcome .section__text {
      line-height: 1.6rem; }
  .welcome__info {
    width: 48.6rem;
    max-width: 100%;
    margin: 0 auto;
    text-align: left; }
  .section__sub-header {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem; }
  .section__text {
    font-size: 1.2rem;
    line-height: 2rem; }
  .resort .section__text {
    font-size: 1.2rem;
    line-height: 2rem; }
  .text-normal {
    font-size: 1.5rem;
    line-height: 3rem;
    width: 100%; }
  .section__header {
    font-size: 3rem;
    line-height: 4rem; }
  .resort {
    padding-bottom: 26.5rem;
    height: auto; }
  .resort__container {
    margin-top: 4rem;
    justify-content: center; }
  .resort__info {
    width: 48.6rem;
    max-width: 100%; }
  .dahang {
    padding-top: 8.5rem;
    padding-bottom: 40rem;
    height: auto; }
  .dahang__info {
    width: 48.6rem;
    max-width: 100%;
    margin: 0 auto; }
  .fukuok {
    height: auto;
    padding-top: 8rem;
    padding-bottom: 38rem;
    background-size: 84% auto; }
    .fukuok .section__order {
      float: right; }
  .fukuok__container {
    justify-content: center; }
  .fukuok__info {
    width: 48.6rem; }
  .fanet {
    height: auto;
    padding-top: 7.8rem;
    padding-bottom: 33rem; }
  .fanet__container {
    width: 48.6rem;
    max-width: 100%;
    margin: 0 auto; }
  .pingu__container {
    width: 48.6rem;
    max-width: 100%;
    margin: 0 auto; }
  .kizimkazi__container {
    width: 48.6rem;
    max-width: 100%;
    margin: 0 auto; }
  .uroa__container {
    width: 48.6rem;
    max-width: 100%;
    margin: 0 auto; }
  .hotels {
    padding-top: 4.7rem;
    padding-bottom: 2rem; }
  .hotels__grid {
    margin-top: 1rem; }
  .hotels__item {
    margin-top: 2rem; }
  .know {
    padding-top: 4rem;
    padding-bottom: 40rem;
    height: auto;
    background-size: 82.5% auto; }
  .know__info__item {
    margin-top: 3.5rem; }
  .know__info {
    width: 48rem;
    max-width: 100%;
    max-width: 100%;
    margin: 0 auto; }
  .why {
    margin-top: 7.8rem;
    background-size: 100% auto;
    background-position: 50% calc(100% - 2.2rem);
    height: 60rem; }
  .why__container {
    margin-top: 5.6rem; }
  .why__item {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .order_modal {
    width: 48.6rem;
    height: 30.0rem; }
  .order_modal__container {
    padding: 1.5rem; }
  .order_modal__form {
    width: 21rem;
    padding-top: 3.3rem;
    padding-left: 2.0rem;
    padding-right: 2.0rem; }
  .action__form__header {
    font-size: 1.5rem;
    line-height: 2rem; }
  .action__form__inputs {
    margin-top: 0.5rem; }
  .action__form__input {
    margin-top: 1rem; }
  .action__form__input {
    width: 16rem;
    height: 2.3rem;
    font-weight: 300;
    font-size: 1.2rem; }
  .action__form__btn {
    width: 16rem;
    height: 2.3rem;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 1rem; }
  .header {
    transition: all 0.2s ease; }
  .header__burger {
    width: 4.3rem;
    height: 3.3rem;
    background-color: transparent;
    border: 0px;
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  .burger_close {
    visibility: hidden;
    opacity: 0; }
  .header__soc {
    display: none; }
  .header__order {
    display: none; }
  .burger_open {
    transition: all 0.2s ease;
    position: absolute;
    top: 0;
    left: 0; }
  .burger_close {
    width: 2.5rem;
    height: 2.5rem;
    transition: all 0.2s ease;
    position: absolute;
    top: 0;
    left: 0.9rem; }
  .mobile-menu-open .header__burger .burger_open {
    visibility: hidden;
    opacity: 0; }
  .mobile-menu-open .header__burger .burger_close {
    visibility: visible;
    opacity: 1; }
  .mobile-menu-open .header {
    background: #fff; }
  .mobile-menu-open .header__nav {
    visibility: visible;
    opacity: 1;
    transform: translate(0px, 0px); }
  .header__container {
    width: 48rem;
    margin: 0 auto; }
  .header__nav {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    transform: translate(0px, -20px);
    position: absolute;
    top: 10.2rem;
    left: 0px;
    right: 0px;
    margin-left: 0;
    z-index: 10;
    background: #2e709a; }
    .header__nav .header_menu li:last-child {
      display: none; }
    .header__nav li {
      display: block;
      margin-left: 0;
      margin-right: 0; }
    .header__nav li a {
      background: #2E709A;
      width: 100%;
      height: 5.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-left: 4rem;
      padding-right: 4rem;
      color: #fff;
      font-size: 1.3rem;
      line-height: 2.8rem; }
      .header__nav li a:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 4rem;
        right: 4rem;
        bottom: 0;
        height: 1px;
        background: rgba(7, 180, 194, 0.5); }
      .header__nav li a:hover {
        background: #89DEF9;
        color: #345074; }
      .header__nav li a.active {
        background: #89DEF9;
        color: #345074; }
  .header_shadow .header__nav {
    top: 5.5rem; }
  .header__burger {
    display: block; }
  .welcome__bg {
    height: 33.5rem;
    bottom: 0;
    top: auto; }
  .resort__bg {
    background-size: 100% auto;
    width: 66%; }
  .dahang__bg {
    width: 57.8rem;
    height: 38.3rem;
    right: 0;
    left: auto;
    background-size: 100% auto; }
  .fanet {
    background-size: 50.9rem 38.8rem; }
  .know__info__item__text {
    font-size: 1.2rem;
    line-height: 2rem;
    max-width: 41rem;
    margin-left: 2rem; }
  .why__item__text {
    font-size: 1.2rem;
    line-height: 1.6rem; }
  .why__img {
    height: 6.5rem; }
  .footer__text {
    margin-left: 1rem;
    font-size: 0.8rem; }
  .footer__logo__img {
    height: 4.8rem;
    width: auto; }
  .footer__order {
    width: 15rem;
    height: 2.5rem;
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin-left: 0.8rem; }
  .footer__soc a {
    margin-right: 0.7rem; }
  .footer__soc a svg {
    width: 1.8rem;
    height: 1.8rem; }
  .hotels__item__name {
    font-size: 1.6rem;
    line-height: 2.4rem; }
  .hotels__item__bb {
    font-size: 1.2rem; }
  .hotels__item__price {
    font-size: 2rem; }
  .action__form__text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-top: 1rem; } }

@media (max-width: 700px) {
  .only_mobile {
    display: inherit; }
  .only_pc {
    display: none; }
  .main {
    height: 53rem;
    min-height: 0; }
  .main__header img {
    max-width: 31rem; }
  .main__form__input {
    width: 100%; }
  .main__form-bg {
    margin-bottom: 7rem;
    max-width: 24rem;
    height: auto; }
  .main__form__input {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
    height: 3.8rem;
    font-size: 1.4rem; }
  .main__form__submit {
    margin-top: 1rem;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 3.8rem;
    font-size: 1.4rem; }
  .main__header {
    top: 12rem;
    bottom: auto;
    padding-bottom: 0; }
  .welcome {
    padding-top: 3.3rem;
    padding-bottom: 23rem;
    background-size: 95.2% auto; }
    .welcome .section__text {
      font-size: 1.6rem;
      line-height: 2rem; }
  .welcome__bg {
    width: 34.3rem;
    height: 20.6rem;
    background-position: 100% 100%; }
  .section__sub-header {
    font-size: 2.4rem;
    line-height: 2.5rem;
    text-align: left; }
  .welcome__info {
    width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem; }
  .section__text {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-top: 1.5rem;
    text-align: justify; }
  .text-normal {
    margin-top: 1.5rem;
    font-size: 1.6rem;
    line-height: 2.5rem; }
  .section__order.only_mobile {
    display: flex;
    margin: 0 auto;
    margin-top: 1rem; }
  .section__order {
    width: 30rem;
    max-width: 100%;
    font-size: 1.6rem;
    line-height: 2.5rem; }
  .resort {
    padding-top: 3.7rem;
    padding-bottom: 16rem;
    background-size: 92.7% auto; }
    .resort .section__order {
      display: none; }
  .resort .section__text {
    font-size: 1.6rem;
    line-height: 2rem; }
  .resort__bg {
    width: 33.4rem;
    height: 17.6rem; }
  .resort__info {
    width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem; }
  .resort__container {
    margin-top: 3.6rem; }
  .dahang {
    padding-top: 4.2rem;
    padding-bottom: 25rem;
    background-size: 96.4% auto; }
    .dahang .section__order {
      display: none; }
  .dahang__info {
    width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem; }
  .dahang__bg {
    width: 34.7rem;
    height: 25.6rem; }
  .fukuok {
    padding-top: 4rem;
    padding-bottom: 33rem;
    background-size: 98.3% auto; }
    .fukuok .section__order {
      display: none; }
  .fukuok__info {
    width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem; }
  .fanet {
    padding-top: 4.2rem;
    padding-bottom: 55rem;
    background-size: 95.5% auto;
    background-position: 100% calc(100% - 9.4rem);
    position: relative; }
    .fanet .section__order {
      position: absolute;
      bottom: 4.5rem;
      left: calc(50% - 15rem);
      margin-top: 0; }
  .fanet__info {
    position: inherit;
    max-width: 100%; }
  .fanet__container {
    width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem; }
  .hotels {
    padding-bottom: 3rem;
    padding-top: 2rem; }
  .section__header {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 400; }
  .hotels__grid {
    margin: 0 auto;
    margin-top: 1.7rem;
    width: 36rem;
    max-width: 100%;
    padding-left: 3rem;
    padding-right: 3rem; }
  .hotels__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .know {
    background: url(../img/bg_know.png) no-repeat 100% 100%/89.4% auto, url(../img/waves.png) 50% 100%/auto 4.4rem no-repeat, rgba(196, 196, 196, 0.2);
    padding-top: 2rem;
    padding-bottom: 22rem; }
  .know__info__item {
    margin-top: 5rem;
    flex-direction: column; }
  .know__info__item__figure {
    width: 100%; }
  .know__info__item {
    align-items: center; }
  .know__info__item__text {
    max-width: 100%;
    width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 2.2rem;
    margin-left: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: justify; }
  .why {
    margin-top: 0;
    padding-top: 2rem;
    height: auto;
    padding-bottom: 30.4rem;
    position: relative;
    background-position: 0px calc(100% - 5rem);
    background-size: auto 26rem; }
  .why__header {
    margin: 0 auto;
    font-size: 2.8rem;
    line-height: 3.5rem;
    font-weight: 500;
    max-width: 36rem;
    padding-left: 3rem;
    padding-right: 3rem; }
  .why__container {
    margin-top: 0;
    flex-direction: column; }
  .why__item {
    margin: 0 auto;
    margin-top: 4rem;
    width: 36rem;
    max-width: 100%;
    padding-left: 3rem;
    padding-right: 3rem; }
  .why__item__text {
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-top: 2rem; }
  .why__blue__order {
    position: absolute;
    margin-top: 0;
    bottom: 2rem;
    left: calc(50% - 15rem);
    width: 30rem; }
  .footer__container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10.5rem; }
  .footer__logo__img {
    width: 8.6rem; }
  .footer__actions {
    display: none; }
  .footer__text {
    margin-left: 0; }
  .header__container {
    width: 36rem;
    max-width: 100%;
    padding-left: 3rem;
    padding-right: 3rem; }
  .order_modal {
    width: 30rem;
    height: 40rem;
    background: transparent; }
  .order_modal__form {
    width: 100%; }
  .action__form__header {
    font-size: 2.5rem;
    line-height: 4rem; }
  .action__form__text {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-top: 2rem; }
  #order_modal .action__form__header {
    font-size: 2rem;
    line-height: 2.5rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem; }
  #order_modal .order_modal__form {
    padding-left: 0;
    padding-right: 0; }
  .action__form__inputs {
    width: 25rem;
    margin: 0 auto;
    margin-top: 2rem; }
  .action__form__input {
    font-size: 1.4rem;
    width: 100%;
    height: 3.6rem; }
  .action__form__btn {
    width: 25rem;
    height: 3.6rem;
    font-size: 1.6rem;
    line-height: 2.5rem; }
  .resort__bg {
    max-width: 100%; } }

@media (max-width: 330px) {
  .section__order {
    width: 24rem;
    left: calc(50% - 12rem) !important; } }
