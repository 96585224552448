.only_mobile{
    display: none;
}
.only_pc{
    display: block;
}
@media (max-width:990px){
    html{
        font-size: 10px;
    }
    body{
        font-size: 1.2rem;
    }

    .main{
        height: 85rem;
        min-height: 0;
        &:before{
            display: none;
        }
    }
    .main__form-bg{
        margin-bottom: 18rem;
        max-width: 74rem;
        background-size: 108% auto;
    }
    .main__form__input{
        width: 14rem;
        height: 2.7rem;
        font-size: 1.2rem;
    }
    .main__form__submit{
        width: 15.5rem;
        height: 2.7rem;
        font-size: 1rem;
    }
    .welcome{
        padding-top: 2rem;
        height: auto;
        padding-bottom: 32rem;
        & .section__text{
            line-height: 1.6rem;
        }
    }
    .welcome__info{
        width: 48.6rem;
        max-width: 100%;
        margin: 0 auto;
        text-align: left;
    }
    .section__sub-header{
        text-align: center;
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
    .section__text{
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .resort .section__text{
        font-size: 1.2rem;
        line-height: 2rem;
    }
    .text-normal{
        font-size: 1.5rem;
        line-height: 3rem;
        width: 100%;
    }
    .section__header{
        font-size: 3rem;
        line-height: 4rem;
    }

    .resort{
        padding-bottom: 26.5rem;
        height: auto;
    }
    .resort__container{
        margin-top: 4rem;
        justify-content: center;
    }
    .resort__info{
        width: 48.6rem;
        max-width: 100%;
    }

    .dahang{
        padding-top: 8.5rem;
        padding-bottom: 40rem;
        height: auto;
    }
    .dahang__info{
        width: 48.6rem;
        max-width: 100%;
        margin: 0 auto;
    }

    .fukuok{
        height: auto;
        padding-top: 8rem;
        padding-bottom: 38rem;
        background-size: 84% auto;
        & .section__order{
            float: right;
        }
    }
    .fukuok__container{
        justify-content: center;
    }
    .fukuok__info{
        width: 48.6rem;
    }

    .fanet{
        height: auto;
        padding-top: 7.8rem;
        padding-bottom: 33rem;
    }
    .fanet__container{
        width: 48.6rem;
        max-width: 100%;
        margin: 0 auto;
    }

    .pingu__container {
        width: 48.6rem;
        max-width: 100%;
        margin: 0 auto;
    }
    .kizimkazi__container {
        width: 48.6rem;
        max-width: 100%;
        margin: 0 auto;
    }
    .uroa__container {
      width: 48.6rem;
      max-width: 100%;
      margin: 0 auto;
    }

    .hotels{
        padding-top: 4.7rem;
        padding-bottom: 2rem;
    }
    .hotels__grid{
        margin-top: 1rem;
    }
    .hotels__item{
        margin-top: 2rem;
    }

    .know{
        padding-top: 4rem;
        padding-bottom: 40rem;
        height: auto;
        background-size: 82.5% auto;
    }
    .know__info__item{
        margin-top: 3.5rem;
    }
    .know__info{
        width: 48rem;
        max-width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
    .why{
        margin-top: 7.8rem;
        background-size: 100% auto;
        background-position: 50% calc(100% - 2.2rem);
        height: 60rem;
    }
    .why__container{
        margin-top: 5.6rem;
    }
    .why__item{
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }

    .order_modal{
        width: 48.6rem;
        height: 30.0rem;
    }
    .order_modal__container{
        padding: 1.5rem;
    }
    .order_modal__form{
        width: 21rem;
        padding-top: 3.3rem;
        padding-left: 2.0rem;
        padding-right: 2.0rem;
    }
    .action__form__header{
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .action__form__inputs{
        margin-top: 0.5rem;
    }
    .action__form__input{
        margin-top: 1rem;
    }
    .action__form__input{
        width: 16rem;
        height: 2.3rem;
        font-weight: 300;
        font-size: 1.2rem;
    }
    .action__form__btn{
        width: 16rem;
        height: 2.3rem;
        font-weight: 500;
        font-size: 1rem;
        margin-top: 1rem;
    }

    .header{
        transition: all 0.2s ease;
    }
    .header__burger{
        width: 4.3rem;
        height: 3.3rem;
        background-color: transparent;
        border: 0px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
    }
    .burger_close{
        visibility: hidden;
        opacity: 0;
    }
    .header__soc{
        display: none;
    }
    .header__order{
        display: none;
    }
    .burger_open{
        transition: all 0.2s ease;
        position: absolute;
        top: 0;
        left: 0;
    }
    .burger_close{
        width: 2.5rem;
        height: 2.5rem;
        transition: all 0.2s ease;
        position: absolute;
        top: 0;
        left: 0.9rem;
    }
    .mobile-menu-open{
        & .header__burger .burger_open{
            visibility: hidden;
            opacity: 0;
        }
        & .header__burger .burger_close{
            visibility: visible;
            opacity: 1;
        }
        & .header{
            background: #fff;
        }
        & .header__nav {
            visibility: visible;
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
    .header__container{
        width: 48rem;
        margin: 0 auto;
    }
    .header__nav {
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
        transform: translate(0px, -20px);
        position: absolute;
        top: 10.2rem;
        left: 0px;
        right: 0px;
        margin-left: 0;
        z-index: 10;
        background: #2e709a;
        & .header_menu li:last-child{
            display: none;
        }
        & li{
            display: block;
            margin-left: 0;
            margin-right: 0;
        }
        & li a{
            background: #2E709A;
            width: 100%;
            height: 5.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            padding-left: 4rem;
            padding-right: 4rem;
            color: #fff;
            font-size: 1.3rem;
            line-height: 2.8rem;
            &:after{
                content:' ';
                display: block;
                position: absolute;
                left: 4rem;
                right: 4rem;
                bottom: 0;
                height: 1px;
                background: rgba(7, 180, 194, 0.5);
            }
            &:hover{
                background: #89DEF9;
                color: #345074;
            }
            &.active{
                background: #89DEF9;
                color: #345074;
            }
        }
    }
    .header_shadow .header__nav{
        top: 5.5rem;
    }
    .header__burger{
        display: block;
    }
    .welcome__bg{
        height: 33.5rem;
        bottom: 0;
        top: auto;
    }
    .resort__bg{
        background-size: 100% auto;
        width: 66%;
    }
    .dahang__bg{
        width: 57.8rem;
        height: 38.3rem;
        right: 0;
        left: auto;
        background-size: 100% auto;
    }
    .fanet{
        background-size: 50.9rem 38.8rem;
    }
    .know__info__item__text{
        font-size: 1.2rem;
        line-height: 2rem;
        max-width: 41rem;
        margin-left: 2rem;
    }
    .why__item__text{
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
    .why__img{
        height: 6.5rem;
    }
    .footer__text{
        margin-left: 1rem;
        font-size: 0.8rem;
    }
    .footer__logo__img{
        height: 4.8rem;
        width: auto;
    }
    .footer__order{
        width: 15rem;
        height: 2.5rem;
        font-size: 1.2rem;
        line-height: 2.5rem;
        margin-left: 0.8rem;
    }
    .footer__soc a{
        margin-right: 0.7rem;
    }
    .footer__soc a svg{
        width: 1.8rem;
        height: 1.8rem;
    }
    .hotels__item__name{
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
    .hotels__item__bb{
        font-size: 1.2rem;
    }
    .hotels__item__price{
        font-size: 2rem;
    }
    .action__form__text{
        font-size: 1.4rem;
        line-height: 1.6rem;
        margin-top: 1rem;
    }
}

@media (max-width:700px){
    .only_mobile{
        display: inherit;
    }
    .only_pc{
        display: none;
    }
    .main{
        height: 53rem;
        min-height: 0;
    }
    .main__header img{
        max-width: 31rem;
    }
    .main__form__input{
        width: 100%;
    }
    .main__form-bg{
        margin-bottom: 7rem;
        max-width: 24rem;
        height: auto;
    }
    .main__form__input{
        margin-left: 0;
        margin-right: 0;
        margin-top: 1rem;
        height: 3.8rem;
        font-size: 1.4rem;
    }
    .main__form__submit{
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        height: 3.8rem;
        font-size: 1.4rem;
    }
    .main__header{
        top: 12rem;
        bottom: auto;
        padding-bottom: 0;
    }

    .welcome{
        padding-top: 3.3rem;
        padding-bottom: 23rem;
        background-size: 95.2% auto;
        & .section__text{
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }
    .welcome__bg{
        width: 34.3rem;
        height: 20.6rem;
        background-position: 100% 100%;
    }
    .section__sub-header{
        font-size: 2.4rem;
        line-height: 2.5rem;
        text-align: left;
    }
    .welcome__info{
        width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .section__text{
        font-size: 1.6rem;
        line-height: 2rem;
        margin-top: 1.5rem;
        text-align: justify;
    }
    .text-normal{
        margin-top: 1.5rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
    .section__order.only_mobile{
        display: flex;
        margin: 0 auto;
        margin-top: 1rem;
    }
    .section__order{
        width: 30rem;
        max-width: 100%;
        font-size: 1.6rem;
        line-height: 2.5rem;
    }

    .resort{
        padding-top: 3.7rem;
        padding-bottom: 16rem;
        background-size: 92.7% auto;
        & .section__order{
            display: none;
        }
    }
    .resort .section__text{
        font-size: 1.6rem;
        line-height: 2rem;
    }
    .resort__bg{
        width: 33.4rem;
        height: 17.6rem;
    }
    .resort__info{
        width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .resort__container{
        margin-top: 3.6rem;
    }

    .dahang{
        padding-top: 4.2rem;
        padding-bottom: 25rem;
        background-size: 96.4% auto;
        & .section__order{
            display: none;
        }
    }
    .dahang__info{
        width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .dahang__bg{
        width: 34.7rem;
        height: 25.6rem;
    }

    .fukuok{
        padding-top: 4rem;
        padding-bottom: 33rem;
        background-size: 98.3% auto;
        & .section__order{
            display: none;
        }
    }
    .fukuok__info{
        width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .fanet{
        padding-top: 4.2rem;
        padding-bottom: 55rem;
        background-size: 95.5% auto;
        background-position: 100% calc(100% - 9.4rem);
        position: relative;
        & .section__order{
            position: absolute;
            bottom: 4.5rem;
            left: calc(50% - 15rem);
            margin-top: 0;
        }
    }
    .fanet__info{
        position: inherit;
        max-width: 100%;
    }
    .fanet__container{
        width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .hotels{
        padding-bottom: 3rem;
        padding-top: 2rem;
    }
    .section__header{
        font-size: 3rem;
        line-height: 4rem;
        font-weight: 400;
    }
    .hotels__grid{
        margin: 0 auto;
        margin-top: 1.7rem;
        width: 36rem;
        max-width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .hotels__item{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .know{
        background: url(../img/bg_know.png) no-repeat 100% 100% / 89.4% auto, url(../img/waves.png) 50% 100% / auto 4.4rem no-repeat, rgba(196, 196, 196, 0.2);
        padding-top: 2rem;
        padding-bottom: 22rem;
    }
    .know__info__item{
        margin-top: 5rem;
        flex-direction: column;
    }
    .know__info__item__figure{
        width: 100%;
    }
    .know__info__item{
        align-items: center;
    }
    .know__info__item__text{
        max-width: 100%;
        width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
        margin-top: 2.2rem;
        margin-left: 0;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: justify;
    }

    .why{
        margin-top: 0;
        padding-top: 2rem;
        height: auto;
        padding-bottom: 30.4rem;
        position: relative;
        background-position: 0px calc(100% - 5rem);
        background-size: auto 26rem;
    }
    .why__header{
        margin: 0 auto;
        font-size: 2.8rem;
        line-height: 3.5rem;
        font-weight: 500;
        max-width: 36rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .why__container{
        margin-top: 0;
        flex-direction: column;
    }
    .why__item{
        margin: 0 auto;
        margin-top: 4rem;
        width: 36rem;
        max-width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .why__item__text{
        font-size: 1.7rem;
        line-height: 2.3rem;
        margin-top: 2rem;
    }
    .why__blue__order{
        position: absolute;
        margin-top: 0;
        bottom: 2rem;
        left: calc(50% - 15rem);
        width: 30rem;
    }

    .footer__container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10.5rem;
    }
    .footer__logo__img{
        width: 8.6rem;
    }
    .footer__actions{
        display: none;
    }
    .footer__text{
        margin-left: 0;
    }

    .header__container{
        width: 36rem;
        max-width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .order_modal{
        width: 30rem;
        height: 40rem;
        background: transparent;
    }
    .order_modal__form{
        width: 100%;
    }
    .action__form__header{
        font-size: 2.5rem;
        line-height: 4rem;
    }
    .action__form__text{
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 2rem;
    }
    #order_modal .action__form__header{
        font-size: 2rem;
        line-height: 2.5rem;
        padding-left: 2.2rem;
        padding-right: 2.2rem;
    }
    #order_modal .order_modal__form{
        padding-left: 0;
        padding-right: 0;
    }
    .action__form__inputs{
        width: 25rem;
        margin: 0 auto;
        margin-top: 2rem;
    }
    .action__form__input{
        font-size: 1.4rem;
        width: 100%;
        height: 3.6rem;
    }
    .action__form__btn{
        width: 25rem;
        height: 3.6rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
    }
    .resort__bg{
        max-width: 100%;
    }
}

@media (max-width:330px){
    .section__order{
        width: 24rem;
        left: calc(50% - 12rem) !important;
    }
}

@media (min-width: 900px) and (max-width: 1100px){

}


@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {

}